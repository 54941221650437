import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

const LeaderHandbook = ({ data }) => {
	const terms = data.strapiHandbook;

	return (
		<Layout>
			<GatsbySeo
				title="Leader Handbook"
				description="Mandatory read for Tour Leader."
			/>
			<div className="my-20">
				<h1 className="font-bold">Art of Bicycle Trips Leader Handbook</h1>
				<p className="font-light text-xl text-primary max-w-3xl mb-14">
					Here’s everything you need to know before leading our first trip. Please
					read through carefully and get in touch if you need more detail on any
					point.
				</p>
				<ReactMarkdown
					className="prose prose-sm sm:prose"
					children={terms.description}
				/>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query LeaderHandbookQuery {
		strapiHandbook {
			name
			description
		}
	}
`;

export default LeaderHandbook;
